const airports = [
  {
    value: 'Aberdeen',
    label: 'Aberdeen',
    coords: {
      lat: 57.2003,
      lng: -2.2045
    }
  },
  {
    value: 'Belfast City (George Best)',
    label: 'Belfast City (George Best)',
    coords: {
      lat: 54.6147,
      lng: -5.87099
    }
  },
  {
    value: 'Belfast International',
    label: 'Belfast International',
    coords: {
      lat: 54.6624,
      lng: -6.2176
    }
  },
  {
    value: 'Birmingham',
    label: 'Birmingham',
    coords: {
      lat: 52.4514,
      lng: -1.73229
    }
  },
  {
    value: 'Bournemouth',
    label: 'Bournemouth',
    coords: {
      lat: 50.7758,
      lng: -1.83316
    }
  },
  {
    value: 'Bristol',
    label: 'Bristol',
    coords: {
      lat: 51.3867,
      lng: -2.7109
    }
  },
  {
    value: 'Cardiff',
    label: 'Cardiff',
    coords: {
      lat: 51.39822,
      lng: -3.33936
    }
  },
  {
    value: 'Doncaster-Sheffield (Robin Hood)',
    label: 'Doncaster-Sheffield (Robin Hood)',
    coords: {
      lat: 53.4809,
      lng: -1.0119
    }
  },
  {
    value: 'East Midlands',
    label: 'East Midlands',
    coords: {
      lat: 52.8265,
      lng: -1.3306
    }
  },
  {
    value: 'Edinburgh',
    label: 'Edinburgh',
    coords: {
      lat: 55.9486,
      lng: -3.36406
    }
  },
  {
    value: 'Exeter',
    label: 'Exeter',
    coords: {
      lat: 50.7311,
      lng: -3.41488
    }
  },
  {
    value: 'Gatwick North Terminal',
    label: 'Gatwick North Terminal',
    coords: {
      lat: 51.1614,
      lng: -0.175107
    }
  },
  {
    value: 'Gatwick South Terminal',
    label: 'Gatwick South Terminal',
    coords: {
      lat: 51.1561,
      lng: -0.159541
    }
  },
  {
    value: 'Glasgow International',
    label: 'Glasgow International',
    coords: {
      lat: 55.8647,
      lng: -4.4321
    }
  },
  {
    value: 'Glasgow Prestwick',
    label: 'Glasgow Prestwick',
    coords: {
      lat: 55.5085,
      lng: -4.61155
    }
  },
  {
    value: 'Heathrow Terminals 1,2 & 3',
    label: 'Heathrow Terminals 1,2 & 3',
    coords: {
      lat: 51.47,
      lng: -0.4543
    }
  },
  {
    value: 'Heathrow Terminal 4',
    label: 'Heathrow Terminal 4',
    coords: {
      lat: 51.4599,
      lng: -0.4473
    }
  },
  {
    value: 'Heathrow Terminal 5',
    label: 'Heathrow Terminal 5',
    coords: {
      lat: 51.4713,
      lng: -0.4878
    }
  },
  {
    value: 'Humberside',
    label: 'Humberside',
    coords: {
      lat: 53.5823,
      lng: -0.3512
    }
  },
  {
    value: 'Inverness',
    label: 'Inverness',
    coords: {
      lat: 57.5395,
      lng: -4.0635
    }
  },
  {
    value: 'Leeds Bradford',
    label: 'Leeds Bradford',
    coords: {
      lat: 53.8693,
      lng: -1.6598
    }
  },
  {
    value: 'Liverpool',
    label: 'Liverpool',
    coords: {
      lat: 53.3371,
      lng: -2.85323
    }
  },
  {
    value: 'London City',
    label: 'London City',
    coords: {
      lat: 51.5036,
      lng: 0.04806
    }
  },
  {
    value: 'Luton',
    label: 'Luton',
    coords: {
      lat: 51.8798,
      lng: -0.376056
    }
  },
  {
    value: 'Manchester',
    label: 'Manchester',
    coords: {
      lat: 53.3631,
      lng: -2.2635
    }
  },
  {
    value: 'Newcastle',
    label: 'Newcastle',
    coords: {
      lat: 55.037,
      lng: -1.7108
    }
  },
  {
    value: 'Newquay airport',
    label: 'Newquay airport',
    coords: {
      lat: 50.4392,
      lng: -5.0109
    }
  },
  {
    value: 'Norwich',
    label: 'Norwich',
    coords: {
      lat: 52.6698,
      lng: 1.2773
    }
  },
  {
    value: 'Southampton',
    label: 'Southampton',
    coords: {
      lat: 50.9507,
      lng: -1.3613
    }
  },
  {
    value: 'Southend',
    label: 'Southend',
    coords: {
      lat: 51.5697,
      lng: 0.703715
    }
  },
  {
    value: 'Stansted Airport',
    label: 'Stansted Airport',
    coords: {
      lat: 51.8897,
      lng: 0.261785
    }
  },
  {
    value: 'Teesside International',
    label: 'Teesside International',
    coords: {
      lat: 54.5121,
      lng: -1.4345
    }
  }
];

export default airports;
