/* eslint-disable indent */
import dayjs from "dayjs";

const todayTomorrowDateFormat = (
  date: string,
  year: boolean | null | undefined = false,
  time: boolean | null | undefined = true,
  t: (va1: string, val2: string, options?: Record<string, any>) => void
) => {
  if (!date) {
    return "";
  }

  const dayjsDate = dayjs(date);
  const now = dayjs();

  if (dayjsDate.isSame(now, "day")) {
    return time
      ? t("dateFormat:todayAt", "{{date, [Today at] HH:mm}}", {
          date: dayjsDate
        })
      : t("dateFormat:today", "{{date, [Today]}}", { date: dayjsDate });
  }

  if (dayjsDate.isSame(now.add(1, "days"), "day")) {
    return time
      ? t("dateFormat:tomorrowAt", "{{date, [Tomorrow at] HH:mm}}", {
          date: dayjsDate
        })
      : t("dateFormat:tomorrow", "{{date, [Tomorrow]}}", { date: dayjsDate });
  }

  if (!time) {
    return t("dateFormat:dateMonth", "{{date, Do MMM}}", {
      date: dayjsDate
    });
  }

  return year
    ? t("dateFormat:atYear", "{{date, D MMMM YYYY [at] HH:mm}}", {
        date: dayjsDate
      })
    : t("dateFormat:at", "{{date, Do MMMM [at] HH:mm}}", { date: dayjsDate });
};

export default todayTomorrowDateFormat;
