import type { AxiosPromise, AxiosInstance, CancelToken } from "axios";

const getDriveUpListings = (
  id: string,
  cancelToken?: CancelToken
): ((apiInstance: AxiosInstance) => AxiosPromise) => (apiInstance) => {
  return apiInstance.get("/v4/listings/driveup", {
    cancelToken,
    params: {
      "filter[id]": id
    }
  });
};

export default getDriveUpListings;
