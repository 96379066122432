// @ts-nocheck
import React from "react";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeIcon from "../FontAwesomeIcon";
import getSizeClass from "../../../helpers/getFontAwesomeSizeClassname";

type Props = {
  size?: 16 | 24 | 32 | 40;
};
const MagnifyingGlass = ({ size }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <div className={sizeClass}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </div>
  );
};
export default MagnifyingGlass;
