import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setGeolocation } from '../../../../stores/geolocation';

const useGeoLocationChange = () => {
  const dispatch = useDispatch();
  return useCallback(
    (address, coordinates) => {
      dispatch(setGeolocation(address, coordinates));
    },
    [dispatch]
  );
};

export default useGeoLocationChange;
