// @ts-nocheck
import React from "react";
import BrandTypography from "../BrandTypography";
import Button from "../Button";
import styles from "./searchFormFullscreenHeader.module.scss";

type Props = {
  onCancel: () => void;
  header: string;
};
const SearchFormFullscreenHeader = ({ onCancel, header }: Props) => {
  return (
    <div className={styles.fullScreenHeaderContainer}>
      <BrandTypography level={3} className={styles.fullScreenHeader}>
        {header}
      </BrandTypography>

      <Button
        type="button"
        text
        onClick={onCancel}
        className={styles.cancelButton}
      >
        Cancel
      </Button>
    </div>
  );
};
export default SearchFormFullscreenHeader;
