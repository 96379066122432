// @ts-nocheck
/* eslint-disable react/no-danger */
import React from "react";
import Typography from "../Typography";
import styles from "./locationSearchInputResult.module.scss";

type Props = {
  isActive: boolean;
  description: string;
  onMouseOver: () => void;
  onClick: () => void;
  type?: "google" | "justpark" | "message" | "loading";
  iconSlotComponent?: any;
  className?: string;
};
const LocationSearchInputResult = ({
  description,
  isActive,
  onMouseOver,
  onClick,
  type = "google",
  iconSlotComponent = false,
  className = ""
}: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`${isActive && type !== "message" ? styles.active : ""} ${
        styles.base
      } ${className}`}
      data-cy={type !== "loading" ? "search-item-suggestion" : ""}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      <Typography className={styles.text}>
        <span
          dangerouslySetInnerHTML={{
            __html: description
          }}
          data-item-type={type}
        />
      </Typography>
      {!!iconSlotComponent && iconSlotComponent}
    </div>
  );
};
export default LocationSearchInputResult;
