// @ts-nocheck
import React from "react";
import NativeFancyDropdown from "../NativeFancyDropdown";
import styles from "./searchFormDropdownInput.module.scss";

type Props = {
  selected: string;
  onChange: (a: string) => void;
  label: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  cypress: string;
  invalid?: boolean;
  placeholder?: string;
  searchable?: boolean;
};
const SearchFormDropdownInput = ({
  selected,
  onChange,
  label,
  options,
  cypress,
  invalid = false,
  placeholder = "Select an item",
  searchable = true
}: Props) => {
  return (
    <div className={styles.base} data-cy={cypress}>
      <NativeFancyDropdown
        labelColor={invalid ? "error" : "brand"}
        searchable={searchable}
        options={options}
        selected={selected}
        onChange={onChange}
        placeholder={placeholder}
        label={label}
        hero
      />
    </div>
  );
};
export default SearchFormDropdownInput;
