import { useCallback, useRef } from 'react';
import handleFamiliarSpaces from '../../../../helpers/handleFamiliarSpaces';
import useLocalStorage from '../../../../hooks/useLocalStorage';

const useUpdateRecentSearches = (showFamiliarSpaces, limit) => {
  const [recentSearches, setRecentSearches] = useLocalStorage(
    'jp_recentSearches',
    []
  );
  const [favourites] = useLocalStorage('jp_favourites', []);

  const recentSearchesRef = useRef(recentSearches);
  recentSearchesRef.current = recentSearches;

  return useCallback(
    (result) => {
      if (!showFamiliarSpaces) return;

      const existsAsFavourite = favourites.some(
        (item) =>
          item.searchData.googlePlaceId === result.searchData.googlePlaceId
      );

      if (existsAsFavourite) return;

      const newRecentSearches = handleFamiliarSpaces(
        recentSearchesRef.current,
        result,
        limit
      );

      setRecentSearches(newRecentSearches);
    },
    [favourites, limit, setRecentSearches, showFamiliarSpaces]
  );
};

export default useUpdateRecentSearches;
