const getDefaultBookingType = (
  isMonthly?: boolean | null,
  isAirport?: boolean
) => {
  if (isAirport) {
    return "airport";
  }

  if (isMonthly) {
    return "monthly";
  }

  return "hourly";
};

export default getDefaultBookingType;
