import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import LocationSearchInputResult from '@justpark/ui/src/components/LocationSearchInputResult';

import styles from '../../../static/scss/components/favouriteSpaces.module.scss';

type Props = {
  heading: string;
  name: string;
  list: Array<any>;
  onClick: () => void;
  iconSlotComponent?: any;
  onClickIconSlotComponent?: () => void;
};

const SearchFormList = ({
  heading,
  name,
  list,
  onClick,
  iconSlotComponent,
  onClickIconSlotComponent
}: Props) => {
  if (!list?.length) return null;

  return (
    <div className={styles.base} data-cy={name}>
      <Typography size="subtext" bold className={styles.heading} color="mid">
        <div>{heading}</div>
      </Typography>
      {list.map((result) => (
        <LocationSearchInputResult
          key={result.query}
          type={result.type}
          isActive={false}
          description={result.query}
          onMouseOver={() => {}}
          onClick={() => onClick(result)}
          iconSlotComponent={
            <button
              type="button"
              onClick={(e) => onClickIconSlotComponent(e, result)}
              className={styles.iconSlotComponentButton}
            >
              {iconSlotComponent}
            </button>
          }
          className={styles.item}
        />
      ))}
    </div>
  );
};

export default SearchFormList;
