// @ts-nocheck
import React, { Node } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import styles from "./brandTypography.module.scss";

type Props = {
  bold?: boolean;
  color?: "primary" | "light" | "brand" | "muted" | "primary-inverse";
  level?: 1 | 2 | 3;
  children: Node;
  className?: string;
};
const BrandTypography = ({
  children,
  bold = false,
  color = "primary",
  level,
  className = ""
}: Props) => {
  const baseClass = "c-typography";
  const modifiers = {
    bold,
    [`color-${color}`]: true,
    [`h${level}`]: true
  };
  const classname = `${bem(baseClass, null, modifiers, styles)} ${className}`;
  if (level === 1) {
    return <h1 className={classname}>{children}</h1>;
  }
  if (level === 2) {
    return <h2 className={classname}>{children}</h2>;
  }
  if (level === 3) {
    return <h3 className={classname}>{children}</h3>;
  }
  return <p className={classname}>{children}</p>;
};
export default BrandTypography;
