// @ts-nocheck
import React from "react";
import styles from "./loadingDial.module.scss";

const LoadingDial = () => {
  return (
    <div className={styles.base}>
      <div className={styles.dial1} />
      <div className={styles.dial2} />
      <div className={styles.dial3} />
    </div>
  );
};
export default LoadingDial;
