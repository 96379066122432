const getQueryParamSearchType = (monthlyParam) => {
  const location = new URL(window.location);
  const params = new URLSearchParams(location.search);

  if (params.has(monthlyParam, 'STANDARD')) {
    return 'monthly_all';
  }

  if (params.has(monthlyParam, 'WEEKDAYS_ONLY')) {
    return 'monthly_weekdays';
  }

  return 'hourly';
};

export default getQueryParamSearchType;
