// @ts-nocheck
// eslint-disable-next-line
import {
  findPlaceFromQuery,
  geocodeLatLng,
  geolocationToLatLng,
  getPlacePredictions,
  getUkLatLngBounds,
  loadGoogle,
  getPlaceDetails
} from "@justpark/helpers/src/maps/maps";
import CancelToken from "@justpark/api/src/cancelToken";
import debounce from "debounce-promise-with-cancel";
import getDriveUpListings from "@justpark/api/src/requests/getDriveUpListings";
import getSearchDriveupListings from "@justpark/api/src/requests/getSearchDriveupListings";
import { getGeolocation } from "@justpark/helpers/src/geolocation/geolocation";

const DEBOUNCE_TIME = 250;
export default class LocationSearchAPI {
  sessionToken = null;

  apiClient = null;

  locationBias = null;

  constructor(apiClient, locationBias) {
    this.apiClient = apiClient;
    this.locationBias = locationBias;
    this.cancelToken = CancelToken.source();
    this.debouncedGoogleLookUp = debounce(
      (text) => this.googleLookUp(text),
      DEBOUNCE_TIME
    );
    this.debouncedDriveupLookUp = debounce(
      (text) => this.driveupLookUp(text),
      DEBOUNCE_TIME
    );
    this.debouncedDriveupGeoLookup = debounce(
      (lat, lng) => this.driveupGeoLookup(lat, lng),
      DEBOUNCE_TIME
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getCurrentLocation() {
    return getGeolocation({
      maximumAge: 120000
    })
      .then(geolocationToLatLng)
      .then(geocodeLatLng)
      .then((geocoderResult) => {
        const lat = geocoderResult[0].geometry.location.lat();
        const lng = geocoderResult[0].geometry.location.lng();
        const address = geocoderResult[0].formatted_address;
        return {
          lat,
          lng,
          address
        };
      });
  }

  driveupLookUp(input) {
    this.cancelToken.cancel("Request cancelled");
    this.cancelToken = CancelToken.source();
    const queryString =
      input.substring(0, 1) === "#" ? input.substring(1) : input;
    const isThreeNumbers = /^\d{3,}/;
    if (!queryString.match(isThreeNumbers)) {
      return Promise.resolve([]);
    }
    return this.apiClient(
      getDriveUpListings(queryString, this.cancelToken.token)
    )
      .then((response) => {
        return response.data.data.slice(0, 3).map((item) => ({
          type: "justpark",
          place_id: item.id,
          html: `<b>${item.id}:</b> ${item.title}`,
          description: `${item.id}: ${item.title}`,
          payOnExit: item.payOnExit,
          slug: item.slug
        }));
      })
      .catch((err) => {
        if (err.message === "Request cancelled") {
          throw err;
        }
        console.error(err);
        return [];
      });
  }

  driveupGeoLookup(lat, lng) {
    return this.apiClient(
      getSearchDriveupListings({
        lat,
        lng
      })
    )
      .then((response) => {
        return response.data.data.slice(0, 5).map((item) => ({
          type: "justpark",
          place_id: item.id,
          html: `<b>${item.id}:</b> ${item.title}`,
          description: `${item.id}: ${item.title}`,
          payOnExit: item.payOnExit,
          slug: item.slug
        }));
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  getGoogleSessionToken() {
    return loadGoogle().then((g) => {
      if (this.sessionToken === null) {
        this.sessionToken = new g.maps.places.AutocompleteSessionToken();
      }
      return this.sessionToken;
    });
  }

  static getHtmlFromGoogleResult(item) {
    const { description: str } = item;
    if (item.matched_substrings && item.matched_substrings.length > 0) {
      const { length, offset } = item.matched_substrings[0];
      return `${str.substr(0, offset)}<b>${str.substr(
        offset,
        length
      )}</b>${str.substr(offset + length)}`;
    }
    return str;
  }

  googleLookUp(input) {
    const locationBias =
      this.locationBias !== null
        ? {
            center: this.locationBias,
            radius: 16000
          }
        : getUkLatLngBounds();
    return this.getGoogleSessionToken()
      .then((sessionToken) =>
        getPlacePredictions({
          input,
          locationBias,
          sessionToken
        })
      )
      .catch((err) => {
        // If no prediction results fallback to a query search
        if (err.message === "ZERO_RESULTS") {
          return findPlaceFromQuery(input).then((queryResults) => {
            return queryResults.map((result) => ({
              ...result,
              description: result.formatted_address
            }));
          });
        }
        throw err;
      })
      .then((results) =>
        results.map((result) => {
          return {
            ...result,
            type: "google",
            html: LocationSearchAPI.getHtmlFromGoogleResult(result)
          };
        })
      )
      .catch((err) => {
        throw err;
      });
  }

  googleDetails(googlePlaceId) {
    return getPlaceDetails(googlePlaceId, this.sessionToken).then((details) => {
      const coords = {
        lat: details.geometry.location.lat(),
        lng: details.geometry.location.lng()
      };
      this.sessionToken = null;
      return {
        googlePlaceId,
        coords
      };
    });
  }

  driveupGeolookupSearch(lat, lng) {
    const driveupGeoLookupPromise = this.driveupGeoLookup(lat, lng);
    return driveupGeoLookupPromise.then((results) => {
      return results.length > 0
        ? results
        : [
            {
              type: "message",
              html: "No results found"
            }
          ];
    });
  }

  search(query, showDriveup) {
    this.debouncedDriveupLookUp.cancel();
    this.debouncedGoogleLookUp.cancel();
    const driveUpsPromise = showDriveup
      ? this.debouncedDriveupLookUp(query)
      : Promise.resolve([]);
    const googlePromise = this.debouncedGoogleLookUp(query);
    return Promise.allSettled([driveUpsPromise, googlePromise]).then(
      (results) => {
        // Get the fulfilled promises, concat their values
        const data = results
          .filter((item) => item.status === "fulfilled")
          .reduce((acc, curr) => {
            return curr.value.length > 0 ? acc.concat(curr.value) : acc;
          }, []);
        return data.length > 0
          ? data
          : [
              {
                type: "message",
                html: "No results found"
              }
            ];
      }
    );
  }

  driveupSearch(query) {
    this.debouncedDriveupLookUp.cancel();
    const driveUpsPromise = this.debouncedDriveupLookUp(query);
    return driveUpsPromise.then((results) => {
      return results.length > 0
        ? results
        : [
            {
              type: "message",
              html: "No results found"
            }
          ];
    });
  }
}
