/**
 * Stores a flag name into localstorage to build up a list
 * @param flag
 */

import { useState, useEffect } from 'react';

const useLocalStorage = (flag, defaultValue) => {
  const [value, setValue] = useState(() => {
    try {
      const local = localStorage.getItem(flag) || defaultValue;
      return JSON.parse(local);
    } catch (error) {
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(flag, JSON.stringify(value));
    } catch (e) {
      // No-op
    }
  }, [value, flag]);

  return [value, setValue];
};

export default useLocalStorage;
