const handleFamiliarSpaces = (previousList, newItem, limit) => {
  const existingItemIndex = previousList.findIndex(
    (item) => item.searchData.googlePlaceId === newItem.searchData.googlePlaceId
  );

  if (existingItemIndex >= 0) {
    const newList = previousList.filter(
      (item, index) => index !== existingItemIndex
    );
    return [newItem, ...newList];
  }

  const listFiltered = limit ? previousList.slice(0, limit - 1) : previousList;

  return [newItem, ...listFiltered];
};
export default handleFamiliarSpaces;
