// @ts-nocheck
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Element
} from "react";
import { createPortal } from "react-dom";
import dayjs from "dayjs";
import todayTomorrowDateFormat from "@justpark/helpers/src/todayTomorrowDateFormat/todayTomorrowDateFormat";
import Typography from "../Typography";
import BasicButton from "../BasicButton";
import DatePickerWithTimeDropDown from "../DatePickerWithTimeDropDown";
import ConditionalWrapper from "../ConditionalWrapper";
import useModalHook from "../../hooks/useModalHook";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";
import useSynchronisedState from "../../hooks/useSynchronisedState";
import SearchFormFullscreenHeader from "../SearchFormFullscreenHeader";
import ChevronIcon from "../Icons/ChevronIcon";
import styles from "./searchFormDateInput.module.scss";

type T = (b: string, a: string) => string;
type Props = {
  date: string;
  setDate: (date: string) => void;
  type: "from" | "to" | "monthly";
  blockPastDates?: boolean;
  min?: string | null;
  max?: string | null;
  invalid?: boolean;
  setDefaultDates: any;
  verticalAlign?: "top" | "bottom";
  horizontalAlign?: "left" | "right";
  t: T;
  variation?: "default" | "input";
  icon?: Element<any> | null;
  useYear: boolean;
};

const cypress = "search-form-date-input";

const getLabel = (type, t) => {
  switch (type) {
    case "to":
      return t("searchForm:until", "Until");
    case "monthly":
      return t("searchForm:startingOn", "Starting on");
    default:
      return t("searchForm:from", "From");
  }
};

const getFullscreenHeader = (type, t) => {
  switch (type) {
    case "to":
      return t("searchForm:parkUntil", "Park until");
    default:
      return t("searchForm:parkFrom", "Park from");
  }
};

const SearchFormDateInput = ({
  date,
  setDate,
  type,
  blockPastDates = true,
  min = null,
  max = null,
  invalid = false,
  setDefaultDates,
  verticalAlign = "top",
  horizontalAlign = "left",
  t = (key, defaultString) => defaultString,
  icon = <ChevronIcon direction="down" />,
  useYear = false,
  variation = "default"
}: Props) => {
  const [syncDate, setSyncDate] = useSynchronisedState(date);
  const [pickerOpen, setPickerOpen] = useState(false);
  const isMobileLayout = useMatchMediaMatches("(max-width: 639px)");

  const minimumDate = useMemo(() => {
    if (min) {
      return dayjs(min).format("YYYY-MM-DD");
    }
    if (blockPastDates) {
      return dayjs().format("YYYY-MM-DD");
    }
    return null;
  }, [min, blockPastDates]);

  const onDateChange = useCallback(
    (value) => {
      setSyncDate(value);
    },
    [setSyncDate]
  );
  const onDateClose = useCallback(() => {
    setPickerOpen(false);
    setDate(syncDate);
  }, [setDate, syncDate, setPickerOpen]);

  const showInWrapper = pickerOpen && isMobileLayout;

  useModalHook(showInWrapper, "modal-open");

  const verticalAlignClass =
    verticalAlign === "bottom"
      ? styles.datePickerContainerBottom
      : styles.datePickerContainerTop;
  const horizontalAlignClass =
    horizontalAlign === "right" ? styles.datePickerContainerRight : "";
  const label = getLabel(type, t);
  const fullscreenHeader = getFullscreenHeader(type, t);
  const timeText =
    type === "to"
      ? t("searchForm:exitBefore", "Exit before")
      : t("searchForm:enterAfter", "Enter after");

  const onCancel = useCallback(() => {
    setPickerOpen(false);
    setSyncDate(date);
  }, [setSyncDate, date, setPickerOpen]);

  useEffect(() => {
    if (pickerOpen && !date) {
      setSyncDate(setDefaultDates());
    }
  }, [date, pickerOpen, setDefaultDates, setSyncDate]);

  const className = variation === "input" ? styles.input : styles.base;

  return (
    <div className={className} data-cy={`${cypress}-${type}`}>
      <BasicButton
        className={styles.triggerButton}
        onClick={() => {
          setPickerOpen(true);
        }}
      >
        <span className={styles.buttonContent}>
          {variation === "default" && (
            <Typography
              color={invalid ? "error" : "brand"}
              className={styles.label}
            >
              {label}
            </Typography>
          )}

          <Typography className={styles.dateText}>
            <span suppressHydrationWarning>
              {syncDate
                ? todayTomorrowDateFormat(
                    syncDate,
                    useYear,
                    type !== "monthly",
                    t
                  )
                : "Select date and time"}
            </span>
          </Typography>

          <span className={styles.chevron}>{icon}</span>
        </span>
      </BasicButton>

      {pickerOpen && (
        <ConditionalWrapper
          condition={showInWrapper}
          wrapper={(children) => {
            return createPortal(children, document.getElementById("portal"));
          }}
        >
          <div
            className={
              showInWrapper
                ? styles.fullscreenModal
                : `${verticalAlignClass} ${horizontalAlignClass}`
            }
          >
            {showInWrapper && (
              <SearchFormFullscreenHeader
                header={fullscreenHeader}
                onCancel={onCancel}
              />
            )}

            {pickerOpen && !isMobileLayout && (
              <button
                type="button"
                className={styles.datePickerOverlay}
                onClick={onDateClose}
              >
                {t("searchForm:closeDatePicker", "Close date picker")}
              </button>
            )}

            <div className={styles.datePicker}>
              <DatePickerWithTimeDropDown
                isFullscreen={showInWrapper}
                onDateChange={onDateChange}
                date={syncDate}
                showTimePicker={type !== "monthly"}
                onClose={onDateClose}
                buttonText={t("searchForm:dateTimePickerSubmit", "Done")}
                blockDates={() => false}
                timeText={timeText}
                maxDate={max}
                minDate={minimumDate}
                cypress={cypress}
                t={t}
              />
            </div>
          </div>
        </ConditionalWrapper>
      )}
    </div>
  );
};
export default SearchFormDateInput;
