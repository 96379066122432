// eslint-disable
// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect
} from "react";
import { createPortal } from "react-dom";
import LocationSearchAPI from "./locationSearchAPI";
import useSynchronisedState from "../../hooks/useSynchronisedState";
import LocationSearchInputResult from "../LocationSearchInputResult";
import ApiContext from "../ApiContext";
import Typography from "../Typography";
import useOutsideClickHandler from "../../hooks/useOutsideClickHandler";
import BasicButton from "../BasicButton";
import GeoLocationArrowIcon from "../Icons/GeoLocationArrowIcon";
import MagnifyingGlass from "../FontAwesome/MagnifyingGlass";
import LoadingDial from "../LoadingDial";
import ConditionalWrapper from "../ConditionalWrapper";
import LocationSearchInputContext from "./LocationSearchInputContext";
import useMatchMediaMatches from "../../hooks/useMatchMediaMatches";
import useModalHook from "../../hooks/useModalHook";
import Button from "../Button";
import searchDriveupImage from "../../static/images/search_driveup.png";
import styles from "./locationSearchInput.module.scss";

type T = (b: string, a: string) => string;
type Item = {
  description: string;
  html: string;
  place_id: string;
  type: "google" | "justpark" | "message";
  payOnExit: boolean;
  slug: string | undefined | null;
};
type UIProps = {
  wrapperRef: any;
  query: string;
  results: Array<Item>;
  activeItem: Item;
  onSearchQueryChange: (a: string) => void;
  onKeyDown: (a: SyntheticKeyboardEvent<any>) => void;
  setActiveIndex: (a: number) => void;
  selectItem: (a: number) => void;
  onClickGeoLocation: () => void;
  onFocus: () => void;
  showResultsContainer: boolean;
  setShowResultsContainer: (a: boolean) => void;
  isLoading: boolean;
  inputRef: any;
  isFullscreen: boolean;
  onCancel: () => void;
  invalid: boolean;
  label: string | T;
  placeholder: string | T;
  inputFocused: boolean;
  onBlur: () => void;
  showDriveupOnlyForm: boolean;
  resultsSlotComponent: any;
  t: T;
};
const cypress = "location-search-input";
export const LocationSearchInputUI = ({
  wrapperRef,
  results,
  query,
  onSearchQueryChange,
  onKeyDown,
  activeItem,
  setActiveIndex,
  selectItem,
  onFocus,
  showResultsContainer,
  setShowResultsContainer,
  onClickGeoLocation,
  isLoading,
  inputRef,
  isFullscreen,
  onCancel,
  invalid,
  label,
  placeholder,
  inputFocused,
  onBlur,
  showDriveupOnlyForm,
  resultsSlotComponent,
  t
}: UIProps) => {
  const baseStyles = styles.base;
  const baseClass = isFullscreen ? styles.mobileFullscreen : baseStyles;
  const focusedClass = inputFocused && styles.focused;
  const driveupLabel = showDriveupOnlyForm
    ? t("searchForm:driveupLabel", "Pay for parking at")
    : null;
  const placeholderText = showDriveupOnlyForm
    ? t("searchForm:driveupPlaceholder", "Enter location ID")
    : placeholder;
  const noResults = results[0]?.html === "No results found";
  return (
    <div className={baseClass} ref={wrapperRef} data-cy={cypress}>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>
          <div className={styles.inputContainer}>
            <Typography
              color={invalid ? "error" : "brand"}
              className={styles.labelText}
            >
              <span data-cy={`${cypress}-label`}>
                {label || driveupLabel || t("searchForm:label", "Park at")}
              </span>
            </Typography>
            <input
              type="text"
              inputMode={showDriveupOnlyForm ? "numeric" : "text"}
              autoComplete="off"
              spellCheck="false"
              ref={inputRef}
              className={`${styles.input} ${focusedClass}`}
              value={query}
              data-cy="search-input"
              onChange={(event) => {
                onSearchQueryChange(event.target.value);
              }}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              id="search"
              placeholder={placeholderText}
              data-hj-allow="true"
              onBlur={onBlur}
            />
          </div>
          {isLoading ? (
            <div data-cy="search-loader" className={styles.loadingIcon}>
              <LoadingDial />
            </div>
          ) : (
            <div className={styles.searchIcon}>
              <MagnifyingGlass size={24} />
            </div>
          )}
        </label>
        <Button
          type="button"
          text
          onClick={onCancel}
          className={styles.cancelButton}
        >
          {t("searchForm:cancelButton", "Cancel")}
        </Button>
      </div>

      {showResultsContainer ? (
        <div
          className={styles.resultsContainer}
          data-cy="search-results-container"
        >
          <BasicButton onClick={onClickGeoLocation} cypress="get-geolocation">
            <div className={styles.geoLocationItem}>
              <GeoLocationArrowIcon />{" "}
              <Typography>
                {t("searchForm:useCurrentLocation", "Use current location")}
              </Typography>
            </div>
          </BasicButton>

          {results.map((resultItem, index) => {
            const { html, place_id: placeId, type } = resultItem;
            const isActive = activeItem === resultItem;
            return (
              <LocationSearchInputResult
                type={type}
                key={placeId}
                isActive={isActive}
                description={html}
                onMouseOver={() => setActiveIndex(index)}
                onClick={() => {
                  selectItem(index);
                }}
              />
            );
          })}

          {results.length === 0 && !!resultsSlotComponent && (
            <LocationSearchInputContext.Provider
              value={setShowResultsContainer}
            >
              {resultsSlotComponent}
            </LocationSearchInputContext.Provider>
          )}
        </div>
      ) : null}

      {showDriveupOnlyForm &&
        showResultsContainer &&
        isFullscreen &&
        (results.length === 0 || noResults) && (
          <div className={styles.resultsContainerDriveupImage}>
            <img
              src={searchDriveupImage}
              alt="Search driveup"
              width={238}
              height={112}
            />
          </div>
        )}
    </div>
  );
};
type Props = {
  value: string;
  onChange: (
    c: string,
    b:
      | {
          lat: string;
          lng: string;
        }
      | {
          listingId: string;
          slug: string | undefined | null;
          payOnExit: boolean;
        },
    a: string
  ) => void;
  invalid: boolean;
  searchInputRef: any;
  label?: string | T;
  placeholder?: string | T;
  onCancelClick?: () => void;
  locationBias?: {
    lat: number;
    lng: number;
  } | null;
  showDriveupOnlyForm?: boolean;
  showDriveupResults?: boolean;
  resultsSlotComponent?: any;
  t: T;
};
const loadingMessage = [
  {
    type: "loading",
    html: "Searching&hellip;",
    description: "Searching",
    place_id: ""
  }
];
const LocationSearchInput = ({
  value,
  onChange,
  searchInputRef,
  invalid,
  label,
  placeholder = "Enter location or location ID",
  onCancelClick,
  locationBias = null,
  showDriveupOnlyForm = false,
  showDriveupResults = true,
  resultsSlotComponent,
  t
}: Props) => {
  const apiContext = useContext(ApiContext);
  const [results, setResults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useSynchronisedState(value);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
  const [inputFocused, setInputFocused] = useState(false);
  const locationApi = useMemo(
    () => new LocationSearchAPI(apiContext?.jpApiClient, locationBias),
    [apiContext, locationBias]
  );
  const [showResultsContainer, setShowResultsContainer] = useState(
    showDriveupOnlyForm
  );
  const onSearchQueryChange = useCallback(
    (text) => {
      setQuery(text);
      const hasLength = text.length > 0;
      setIsLoading(hasLength);
      const searchComplete = (data) => {
        setIsLoading(false);
        setActiveIndex(0);
        setResults(data);
        setShowResultsContainer(true);
      };
      const onError = (err) => {
        if (err.message !== "Request cancelled") {
          throw err;
        }
      };
      if (showDriveupOnlyForm) {
        locationApi
          .driveupSearch(text)
          .then((data) => {
            searchComplete(data);
          })
          .catch(onError);
      } else {
        locationApi
          .search(text, showDriveupResults)
          .then((data) => {
            searchComplete(data);
          })
          .catch(onError);
      }
      if (hasLength === false) {
        setResults([]);
      }
      if (hasLength === false && !showDriveupOnlyForm) {
        onChange("", {});
      }
    },
    [setQuery, showDriveupOnlyForm, locationApi, showDriveupResults, onChange]
  );
  const activeItem = useMemo(() => {
    const remainder =
      activeIndex % (results.length * Math.sign(activeIndex)) || 0;
    return results[remainder];
  }, [results, activeIndex]);
  const cycleActiveIndex = useCallback(
    (direction) => {
      setActiveIndex((activeValue) => activeValue + direction);
    },
    [setActiveIndex]
  );
  const onItemSelect = useCallback(
    (index) => {
      if (index === -1) {
        return;
      }
      const item = index ? results[index] : activeItem;
      if (item) {
        const { description, place_id: placeId, type, payOnExit, slug } = item;
        setQuery(description);
        if (type !== "message") {
          setShowResultsContainer(false);
        }
        if (type === "google") {
          locationApi.googleDetails(placeId).then((data) => {
            onChange(
              description,
              {
                ...data.coords,
                googlePlaceId: placeId
              },
              type
            );
          });
        }
        if (type === "justpark") {
          onChange(
            description,
            {
              listingId: placeId,
              payOnExit,
              slug
            },
            type
          );
        }
      }
    },
    [activeItem, locationApi, onChange, setQuery, results]
  );
  const wrapperRef = useRef(null);
  const onItemSelectRef = useRef(onItemSelect);
  onItemSelectRef.current = onItemSelect;
  const onOutsideClick = useCallback(() => {
    setShowResultsContainer(false);
  }, [setShowResultsContainer]);
  useEffect(() => {
    // If the results container is closed auto select an item
    // If geolocation was selected the active index will be -1 so nothing to auto select
    if (showResultsContainer === false) {
      onItemSelectRef.current();
    }
  }, [showResultsContainer, onItemSelectRef]);
  useOutsideClickHandler(wrapperRef, onOutsideClick, showResultsContainer);
  const onFocus = useCallback(() => {
    setInputPlaceholder(placeholder ?? "Enter location or location ID");
    setInputFocused(true);
    setShowResultsContainer(true);
  }, [
    setInputPlaceholder,
    setInputFocused,
    setShowResultsContainer,
    placeholder
  ]);
  const onBlur = useCallback(() => {
    setInputPlaceholder(placeholder);
    setInputFocused(false);
  }, [setInputPlaceholder, setInputFocused, placeholder]);
  const selectItem = useCallback(
    (index) => {
      onItemSelect(index);
    },
    [onItemSelect]
  );
  const onKeyDown = useCallback(
    (evt) => {
      switch (evt.keyCode) {
        case 38:
          // Up arrow
          evt.preventDefault();
          cycleActiveIndex(-1);
          break;
        case 40:
          // Down arrow
          evt.preventDefault();
          cycleActiveIndex(1);
          break;
        case 13:
          // Enter Key
          evt.preventDefault();
          onItemSelect();
          break;
        default:
          // Do nothing
          break;
      }
    },
    [cycleActiveIndex, onItemSelect]
  );
  const onClickGeoLocation = useCallback(() => {
    setResults([]);
    setActiveIndex(-1);
    setQuery("");
    setIsLoading(true);
    if (showDriveupOnlyForm) {
      locationApi
        .getCurrentLocation()
        .then(({ lat, lng }) => {
          return locationApi.driveupGeolookupSearch(lat, lng);
        })
        .then((geoResults) => {
          setResults(geoResults);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    } else {
      setShowResultsContainer(false);
      locationApi
        .getCurrentLocation()
        .then(({ address, lat, lng }) => {
          setQuery(address);
          onChange(
            address,
            {
              lat,
              lng
            },
            "geolocation"
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [showDriveupOnlyForm, locationApi, onChange, setQuery]);
  const isMobileLayout = useMatchMediaMatches("(max-width: 639px)");
  const showInWrapper = showResultsContainer && isMobileLayout;
  useModalHook(showInWrapper, "modal-open");
  useEffect(() => {
    if (showResultsContainer && !showDriveupOnlyForm) {
      searchInputRef.current.focus();
    }
  }, [
    showDriveupOnlyForm,
    searchInputRef,
    showInWrapper,
    showResultsContainer
  ]);
  const onCancel = useCallback(() => {
    setResults([]);
    setQuery(value);
    setActiveIndex(-1);
    setShowResultsContainer(false);
    if (onCancelClick) {
      onCancelClick();
    }
  }, [onCancelClick, setQuery, value]);
  return (
    <ConditionalWrapper
      condition={showInWrapper}
      wrapper={(children) => {
        return createPortal(children, document.getElementById("portal"));
      }}
    >
      <LocationSearchInputUI
        inputFocused={inputFocused}
        key="searchInput"
        wrapperRef={wrapperRef}
        inputRef={searchInputRef}
        query={query}
        results={isLoading && results.length === 0 ? loadingMessage : results}
        isLoading={isLoading}
        activeItem={activeItem}
        onSearchQueryChange={onSearchQueryChange}
        onKeyDown={onKeyDown}
        setActiveIndex={setActiveIndex}
        selectItem={selectItem}
        onFocus={onFocus}
        showResultsContainer={showResultsContainer}
        setShowResultsContainer={setShowResultsContainer}
        onClickGeoLocation={onClickGeoLocation}
        isFullscreen={showInWrapper}
        onCancel={onCancel}
        invalid={invalid}
        label={label}
        placeholder={inputPlaceholder}
        onBlur={onBlur}
        showDriveupOnlyForm={showDriveupOnlyForm}
        resultsSlotComponent={resultsSlotComponent}
        t={t}
      />
    </ConditionalWrapper>
  );
};
export default LocationSearchInput;
