// @ts-nocheck
import { useEffect, useState } from "react";

const useSynchronisedState = (value) => {
  const [stateValue, setStateValue] = useState(value);
  useEffect(() => {
    setStateValue(value);
  }, [value, setStateValue]);
  return [stateValue, setStateValue];
};
export default useSynchronisedState;
