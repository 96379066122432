// @ts-nocheck
import React, { useMemo } from "react";
import BasicButton from "../BasicButton";
import Typography from "../Typography";
import PlaneUp from "../FontAwesome/PlaneUp";
import styles from "./searchFormTabs.module.scss";

type T = (b: string, a: string) => string;
type Props = {
  active: "hourly" | "monthly" | "airport";
  onChange: (value: "hourly" | "monthly" | "airport") => void;
  firstTab?: "hourly" | "monthly" | "airport";
  t: T;
  showAirport?: boolean;
};
const cypress = "search-form-tabs";
const options = [
  {
    value: "hourly",
    key: "searchForm:hourlyDaily",
    label: "Hourly/Daily",
    icon: false
  },
  {
    value: "monthly",
    key: "searchForm:monthly",
    label: "Monthly",
    icon: false
  },
  {
    value: "airport",
    key: "searchForm:airport",
    label: "Airport",
    icon: <PlaneUp />
  }
];
const SearchFormTabs = ({
  active,
  onChange,
  firstTab = "hourly",
  t,
  showAirport = false
}: Props) => {
  const sortedOptions = useMemo(() => {
    return [...options].sort((item) => {
      if (item.value === firstTab) {
        return -1;
      }
      return 0;
    });
  }, [firstTab]);
  return (
    <div className={styles.base} data-cy={cypress}>
      {sortedOptions.map(({ value, key, label, icon }) => {
        if (value === "airport" && showAirport === false) {
          return null;
        }
        const isActive = value === active;
        return (
          <BasicButton
            key={value}
            onClick={() => {
              onChange(value);
            }}
            cypress={`${cypress}${isActive ? "-active" : ""}`}
          >
            <Typography bold color="primary">
              <div className={isActive ? styles.activeTab : styles.tab}>
                {icon && <span className={styles.icon}>{icon}</span>}
                {t(key, label)}
              </div>
            </Typography>
          </BasicButton>
        );
      })}
    </div>
  );
};
export default SearchFormTabs;
