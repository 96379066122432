// @ts-nocheck
import React from "react";
import { faHeart as faHeartOutline } from "@fortawesome/pro-regular-svg-icons";
import FontAwesomeIcon from "../FontAwesomeIcon";
import getSizeClass from "../../../helpers/getFontAwesomeSizeClassname";

type Props = {
  size?: 14 | 16 | 24 | 32 | 40;
  className?: string;
};
const HeartOutline = ({ size, className = "" }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <div className={`${sizeClass} ${className}`}>
      <FontAwesomeIcon icon={faHeartOutline} className={sizeClass} />
    </div>
  );
};
export default HeartOutline;
