// @ts-nocheck
import React, { useCallback, memo } from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localDateTimeIso8601 from "@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601";
import getDefaultBookingType from "@justpark/helpers/src/getDefaultBookingType/getDefaultBookingType";
import LocationSearchInput from "../LocationSearchInput";
import SearchFormDateInput from "../SearchFormDateInput";
import ArrowRight from "../FontAwesome/ArrowRight";
import Typography from "../Typography";
import SearchFormTabs from "../SearchFormTabs";
import SearchFormMonthlyDurationInput from "../SearchFormMonthlyDurationInput";
import SearchFormDropdownInput from "../SearchFormDropdownInput";
import defaultStyles from "./searchForm.module.scss";
import horizontalStyles from "./searchFormHorizontal.module.scss";

dayjs.extend(isSameOrBefore);
type T = (b: string, a: string) => string;
type Props = {
  query: string;
  coords: {
    lat: number;
    lng: number;
  } | null;
  arriving: string;
  leaving: string;
  airportArriving: string;
  airportLeaving: string;
  onChange: (b: any, a: any) => void;
  bookingType: "hourly" | "monthly" | "airport";
  monthlyStarting: string;
  monthlyDuration: string;
  showValidation: boolean;
  searchInputRef: any;
  handleOnGooglePlaceChange?: (a: any) => void;
  geoLocationChange: (a: any) => void;
  setDefaultHourlyDates: {
    arriving: string;
    leaving: string;
  };
  setDefaultMonthlyDates: {
    monthlyDuration: string;
  };
  label?: string;
  placeholder?: string;
  locationBias?: {
    lat: number;
    lng: number;
  } | null;
  resultsSlotComponent?: any;
  isMonthlyDefault?: boolean;
  isAirportDefault?: boolean;
  datePickerVerticalAlign?: "top" | "bottom";
  datePickerHorizontalAlign?: "left" | "right";
  horizontalLayout?: boolean;
  showAirport?: boolean;
  selectedAirport?: string;
  airports?: [];
  hideTabs?: boolean;
  t: T;
};
const SearchForm = ({
  query,
  coords,
  arriving,
  leaving,
  airportArriving,
  airportLeaving,
  onChange,
  bookingType,
  monthlyStarting,
  monthlyDuration,
  searchInputRef,
  handleOnGooglePlaceChange,
  geoLocationChange,
  showValidation,
  setDefaultHourlyDates,
  setDefaultMonthlyDates,
  label,
  placeholder = "Enter location or location ID",
  locationBias = null,
  resultsSlotComponent,
  isMonthlyDefault = false,
  datePickerVerticalAlign = "top",
  datePickerHorizontalAlign = "left",
  horizontalLayout = false,
  showAirport = false,
  selectedAirport = "",
  airports = [],
  hideTabs = false,
  t,
  isAirportDefault
}: Props) => {
  // The horizontal layout was only built to accommodate tablet sizes and higher
  const styles = horizontalLayout ? horizontalStyles : defaultStyles;
  const validatedOnChange = useCallback(
    (newData) => {
      return onChange((existingData) => {
        const mergedData = {
          ...existingData,
          ...newData
        };
        [
          ["arriving", "leaving"],
          ["airportArriving", "airportLeaving"]
        ].forEach(([arrivingProp, leavingProp]) => {
          const {
            [arrivingProp]: newArriving,
            [leavingProp]: newLeaving
          } = mergedData;
          if (newArriving) {
            const arrivingDate = dayjs(newArriving);
            const leavingDate = dayjs(newLeaving);
            if (
              leavingDate.isValid() === false ||
              leavingDate.isSameOrBefore(arrivingDate)
            ) {
              mergedData[leavingProp] = arrivingDate
                .add(4, "hours")
                .format(localDateTimeIso8601);
            }
          }
        });
        return mergedData;
      });
    },
    [onChange]
  );
  const onLocationChange = useCallback(
    (value, searchData, type) => {
      const newData = {
        query: value,
        searchData
      };
      validatedOnChange(newData);
      const { lat, lng } = searchData;
      if (type === "geolocation") {
        geoLocationChange(value, {
          lat,
          lng
        });
      }
      if (handleOnGooglePlaceChange && type === "google" && lat && lng) {
        handleOnGooglePlaceChange(newData);
      }
    },
    [geoLocationChange, handleOnGooglePlaceChange, validatedOnChange]
  );
  const onPropChange = useCallback(
    (prop: string) => (value) =>
      validatedOnChange({
        [prop]: value
      }),
    [validatedOnChange]
  );
  const onArrivingChange = onPropChange("arriving");
  const onLeavingChange = onPropChange("leaving");
  const onBookingTypeChange = onPropChange("bookingType");
  const onMonthlyStartingChange = onPropChange("monthlyStarting");
  const onMonthlyDurationChange = onPropChange("monthlyDuration");
  const onAirportArrivingChange = onPropChange("airportArriving");
  const onAirportLeavingChange = onPropChange("airportLeaving");
  return (
    <div className={styles.flex}>
      {!horizontalLayout && (
        <>
          {hideTabs === false && (
            <div className={styles.tabContainer}>
              <SearchFormTabs
                active={bookingType}
                onChange={onBookingTypeChange}
                firstTab={getDefaultBookingType(
                  isMonthlyDefault,
                  isAirportDefault && showAirport
                )}
                showAirport={showAirport}
                t={t}
              />
            </div>
          )}

          <Typography
            size="secondary"
            color="text-mid"
            bold
            className={styles.inputDescription}
          >
            {t("searchForm:where", "Where?")}
          </Typography>
        </>
      )}

      <div className={styles.locationSearchInputContainer}>
        {bookingType !== "airport" ? (
          <LocationSearchInput
            value={query}
            onChange={onLocationChange}
            searchInputRef={searchInputRef}
            invalid={showValidation && !coords}
            label={label}
            placeholder={placeholder}
            locationBias={locationBias}
            showDriveupResults={bookingType === "hourly"}
            resultsSlotComponent={resultsSlotComponent}
            t={t}
          />
        ) : (
          <SearchFormDropdownInput
            invalid={showValidation}
            selected={selectedAirport}
            onChange={(value) => {
              onChange((existing) => ({
                ...existing,
                airport: value
              }));
            }}
            label={t("searchForm:airportLabel", "Airport")}
            placeholder={t("searchForm:airportPlaceholder", "Select an item")}
            options={airports}
            cypress="search-form-airports"
          />
        )}
      </div>

      {!horizontalLayout && (
        <Typography
          className={styles.inputDescription}
          size="secondary"
          color="text-mid"
          bold
        >
          {t("searchForm:when", "When?")}
        </Typography>
      )}

      <div className={styles.durationContainer}>
        {bookingType === "hourly" || bookingType === "airport" ? (
          <SearchFormDateInput
            type="from"
            date={bookingType === "hourly" ? arriving : airportArriving}
            setDate={
              bookingType === "hourly"
                ? onArrivingChange
                : onAirportArrivingChange
            }
            key={`arriving-${arriving}`}
            blockPastDates
            invalid={showValidation && !arriving}
            setDefaultDates={setDefaultHourlyDates}
            verticalAlign={datePickerVerticalAlign}
            horizontalAlign={datePickerHorizontalAlign}
            t={t}
          />
        ) : (
          <SearchFormDateInput
            type="monthly"
            date={monthlyStarting}
            setDate={onMonthlyStartingChange}
            invalid={showValidation && !monthlyStarting}
            blockPastDates
            setDefaultDates={setDefaultMonthlyDates}
            verticalAlign={datePickerVerticalAlign}
            horizontalAlign={datePickerHorizontalAlign}
            t={t}
          />
        )}

        {!horizontalLayout && (
          <div className={styles.durationIcon}>
            <ArrowRight size={24} />
          </div>
        )}

        {bookingType === "hourly" || bookingType === "airport" ? (
          <SearchFormDateInput
            type="to"
            date={bookingType === "hourly" ? leaving : airportLeaving}
            setDate={
              bookingType === "hourly"
                ? onLeavingChange
                : onAirportLeavingChange
            }
            key={`leaving-${leaving}`}
            blockPastDates
            invalid={showValidation && !leaving}
            min={bookingType === "hourly" ? arriving : airportArriving}
            setDefaultDates={setDefaultHourlyDates}
            verticalAlign={datePickerVerticalAlign}
            horizontalAlign={datePickerHorizontalAlign}
            t={t}
          />
        ) : (
          <SearchFormMonthlyDurationInput
            selected={monthlyDuration}
            onChange={onMonthlyDurationChange}
            showFixedOption={horizontalLayout}
            horizontalAlign={datePickerHorizontalAlign}
            t={t}
          />
        )}
      </div>
    </div>
  );
};
export default memo(SearchForm);
