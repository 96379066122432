import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LocationSearchInputContext from '@justpark/ui/src/components/LocationSearchInput/LocationSearchInputContext';
import Heart from '@justpark/ui/src/components/FontAwesome/Heart';
import HeartOutline from '@justpark/ui/src/components/FontAwesome/HeartOutline';
import { show as showSnackbar } from '@justpark/snackbar/src/reducers/snackbar';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { ampli } from '../../../helpers/Tracking/amplitude';
import SearchFormList from '../../Home/SearchFormList';
import handleFamiliarSpaces from '../../../helpers/handleFamiliarSpaces';

import styles from '../../../../static/scss/components/favouriteSpaces.module.scss';

type Props = {
  onSearchDataChange: () => void;
};

const ConnectedFavouriteSearches = ({ onSearchDataChange }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setShowResultsContainer = useContext(LocationSearchInputContext);
  const [recentSearches, setRecentSearches] = useLocalStorage(
    'jp_recentSearches',
    []
  );
  const [favourites, setFavourites] = useLocalStorage('jp_favourites', []);

  const handleItemClick = (result) => {
    setShowResultsContainer(false);
    onSearchDataChange((existingData) => ({
      ...existingData,
      ...result
    }));

    ampli.favouriteSearchClicked();
  };

  const handleFavouritesIconClick = (e, result) => {
    e.stopPropagation();

    const newFavourites = favourites.filter(
      (item) =>
        item.searchData.googlePlaceId !== result.searchData.googlePlaceId
    );

    setFavourites(newFavourites);

    ampli.favouriteRemoved();
    ampli.identify(undefined, {
      favourite_search_count: newFavourites.length
    });
  };

  const handleRecentIconClick = (e, result) => {
    e.stopPropagation();

    const newFavourites = handleFamiliarSpaces(favourites, result);
    setFavourites(newFavourites);

    setRecentSearches(
      recentSearches.filter(
        (item) =>
          item.searchData.googlePlaceId !== result.searchData.googlePlaceId
      )
    );

    dispatch(
      showSnackbar(
        t(
          'homePage:addedToFavourites',
          { location: result.query },
          `Added ${result.query} to favourites`
        )
      )
    );

    ampli.favouriteAdded();
    ampli.identify(undefined, {
      favourite_item_count: newFavourites.length
    });
  };

  return (
    <>
      <SearchFormList
        heading={t('homePage:favouriteSearches', 'Favourite searches')}
        name="favourite-searches"
        list={favourites}
        onClick={handleItemClick}
        iconSlotComponent={<Heart className={styles.heart} />}
        onClickIconSlotComponent={handleFavouritesIconClick}
      />

      <SearchFormList
        heading={t('homePage:recentSearches', 'Recent searches')}
        name="recent-searches"
        list={recentSearches}
        onClick={handleItemClick}
        iconSlotComponent={<HeartOutline className={styles.heartOutline} />}
        onClickIconSlotComponent={handleRecentIconClick}
      />
    </>
  );
};

export default ConnectedFavouriteSearches;
