import type { AxiosPromise, AxiosInstance } from "axios";

type GetSearchDriveupListings = {
  lat: number;
  lng: number;
};

const getSearchDriveupListings = ({
  lat,
  lng
}: GetSearchDriveupListings): ((
  apiInstance: AxiosInstance
) => AxiosPromise) => (apiInstance) => {
  const config = {
    params: {
      lat,
      lng
    }
  } as const;

  return apiInstance.get("/v5/search/driveup", config);
};

export default getSearchDriveupListings;
