// @ts-nocheck
import React from "react";
import SearchFormDropdownInput from "../SearchFormDropdownInput";

type T = (b: string, a: string) => string;
type Props = {
  selected: string;
  onChange: (a: string) => void;
  showFixedOption?: boolean;
  t: T;
};
const optionData = (showFixedOption, t) => {
  return [
    {
      value: "WEEKDAYS_ONLY",
      label: t("searchForm:weekdaysOnlyDurationName", "Mon-Fri only")
    },
    {
      value: "STANDARD",
      label: t("searchForm:standardDurationName", "7 days per week")
    },
    ...(showFixedOption
      ? [
          {
            value: "FIXED",
            label: t("searchForm:fixedDurationName", "Less than a month")
          }
        ]
      : [])
  ];
};
const cypress = "search-form-monthly-duration-input";
const SearchFormMonthlyDurationInput = ({
  selected,
  onChange,
  showFixedOption = false,
  t
}: Props) => {
  const optionsObject = optionData(showFixedOption, t);
  return (
    <SearchFormDropdownInput
      selected={selected}
      onChange={onChange}
      label={t("searchForm:monthlyDuration", "Monthly Duration")}
      options={optionsObject}
      cypress={cypress}
      searchable={false}
    />
  );
};
export default SearchFormMonthlyDurationInput;
