type Props = {
  query?: string;
  arriving: any;
  leaving?: any;
  coords: {
    lat: string;
    lng: string;
  };
  monthlyDuration?: string;
  monthlyStarting?: string;
  bookingType: string;
  filters?: Array<string>;
  googlePlaceId?: string;
  sort: string | undefined | null;
  showAirportOnboarding: boolean;
  airport: boolean;
};

const buildSearchQueryParams = ({
  query,
  arriving,
  leaving,
  coords,
  monthlyDuration,
  monthlyStarting,
  bookingType,
  filters,
  googlePlaceId,
  sort,
  showAirportOnboarding = false,
  airport = false
}: Props) => {
  const { lat, lng } = coords;

  const params = new URLSearchParams({
    ...(bookingType !== 'monthly'
      ? { arriving, leaving }
      : {
          arriving: monthlyStarting,
          monthly_duration: '0',
          monthly_type: monthlyDuration
        }),
    ...(query ? { q: query } : {}),
    ...(coords ? { coords: `${lat},${lng}` } : {}),
    ...(googlePlaceId ? { place_id: googlePlaceId } : {}),
    ...(filters ? { filters } : {}),
    ...(sort ? { sort } : {}),
    ...(bookingType === 'airport' || airport
      ? {
          airport: true
        }
      : {}),
    ...(showAirportOnboarding ? { airport_onboarding: true } : {})
  });

  return params.toString();
};

export default buildSearchQueryParams;
